(<template>
  <span class="magic-link-handler"></span>
</template>)

<script>
  import JobActionsPrototype from '@/prototypes/JobActionsPrototype';

  export default {
    extends: JobActionsPrototype,
    data() {
      return {
        isCancelAction: this.$route.query.action === 'cancel',
        isWithdrawAction: this.$route.query.action === 'withdraw'
      };
    },
    mounted() {
      if (this.isCancelAction) {
        this.catchReject();
      }
      if (this.isWithdrawAction && this.userIsInterpreter) {
        this.catchDecline();
      }
    }
  };
</script>
